/**
 * Created by brunekninja on 29/09/2016.
 */
(function($) {
    $(document).on('click', '.favourite', function (event) {
        var postID, userID, type;
        var $this = $(this);
        postID = $(this).data('id');
        type = $(this).data('type');
        $('.favourite').attr('disabled', 'disabled');
        $('.spinner').css({opacity: '1', visibility: 'visible'});
        $.ajax({
            url: ajax_feed_obj.ajaxurl,
            type: 'post',
            data: {
                action: 'favorite_posts',
                post_ID: postID,
                type: type
            },
            success: function( response ) {
                console.log(response);
                $('.favourite').removeAttr('disabled');
                $('.spinner').css({opacity: '0', visibility: 'hidden'});
                if (type === 'add'){
                    $this.addClass('liked').data('type', 'remove').find('i').removeClass().addClass('icon-favorite-added');
                }
                if (type === 'remove') {
                  $this.removeClass('liked').data('type', 'add').find('i').removeClass().addClass('icon-favorite');
                }
                if (type === 'remove' && $('body').hasClass('member-account')){
                    $this.removeClass('liked').data('type', 'add').find('i').removeClass().addClass('icon-favorite');
                    $this.closest('.content-box').css('display','none');
                }

            }
        });
    });
})(jQuery);
